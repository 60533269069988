export function useToggleMenu() {
  const toggleMenu = (className: string): void => {
    document.querySelector('body')?.classList.toggle(className)
  }

  return {
    toggleMenuAccount: () => toggleMenu('show-menu-account'),
    toggleMenuDeposit: () => toggleMenu('show-menu-deposit'),
    toggleMenuWithdraw: () => toggleMenu('show-menu-withdraw')
  }
}
